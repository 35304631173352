<template>
  <b-row class="discussion-wrapper">
    <b-col>
      <b-row>
        <b-col>
          <div class="discussion-header">
            <b-row>
              <b-col :class="{ 'text-right': language === 'ar' }"
                ><span class="discussion-header__breadcumb"
                  >{{ $t("discussions.all_topics") }}
                  <b v-for="(b, index) in getDiscussionBreadcrumb" :key="index">
                    &gt; {{ b.name }}</b
                  >
                </span></b-col
              >
              <b-col
                :class="{
                  'col-4': true,
                  'text-right': language !== 'ar',
                  'text-left': language === 'ar'
                }"
              >
                <button
                  class="btn ob-btn ob-btn-primary ml-3"
                  @click="showAddPostForm"
                  id="add-post-btn"
                >
                  + {{ $t("discussions.post.add") }}
                </button>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-3 discussion-body">
        <div class="col-lg-4 left-nav">
          <LeftNavDiscussion
            :isLoadingFlag="isLoading"
            @navClicked="getListOfPosts()"
          />
        </div>
        <div class="col-lg-8 right-container">
          <RightNavDiscussion
            :isLoadingFlag="isLoadingPostListFlag"
            @newPostAdded="getListOfPosts()"
          />
        </div>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
// TODO: fix the unit test cases for the latest version
import LeftNavDiscussion from "@/components/SelfPaced/Discussion/LeftNavDiscussion.vue";
import RightNavDiscussion from "@/components/SelfPaced/Discussion/RightNavDiscussion.vue";
import { mapGetters } from "vuex";
import discussionViewSelectorMixin from "@/core/mixins/discussionViewSelector.js";
export default {
  mixins: [discussionViewSelectorMixin],
  components: { LeftNavDiscussion, RightNavDiscussion },
  data() {
    return {
      showError: false,
      isLoading: true,
      isLoadingPostListFlag: false
    };
  },
  mounted() {
    this.$store.commit("SET_TOPIC_ID", "");

    this.$store.commit(
      "SET_DISCUSSION_VIEW_SELECTOR",
      this.DISCUSSION_VIEW_SELECTOR.IntroPage
    );

    this.$store
      .dispatch("getDiscussionTopics", this.$route.params.id)
      .then(() => {
        this.isLoading = false;
      });
  },
  computed: {
    ...mapGetters(["getTopicId", "getDiscussionBreadcrumb", "language"])
  },
  methods: {
    showAddPostForm() {
      this.$store.commit(
        "SET_DISCUSSION_VIEW_SELECTOR",
        this.DISCUSSION_VIEW_SELECTOR.AddPostForm
      );
    },
    getListOfPosts(page = 1) {
      this.$store.commit(
        "SET_DISCUSSION_VIEW_SELECTOR",
        this.DISCUSSION_VIEW_SELECTOR.PostsList
      );

      this.isLoadingPostListFlag = true;
      let obj = {
        course_id: this.$route.params.id,
        topic_id: this.getTopicId,
        page: page
      };
      this.$store.dispatch("getListOfPosts", obj).then(() => {
        this.isLoadingPostListFlag = false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.discussion-wrapper {
  height: 100%;
  .discussion-header {
    padding: 10px 10px 10px 20px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;

    .discussion-header__breadcumb {
      vertical-align: bottom;
      line-height: 17px;
      font-size: 12px;
    }
  }
  .discussion-body {
    height: calc(100% - 74px);
  }
}
</style>
